/* Stats.css */

.chart-container {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 30px auto; 
  width: 90%; 
  max-width: 1000px; 
  min-height: 700px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; 
}

.chart-container .bar-chart {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  max-width: 700px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-container .bar-chart .recharts-bar {
  fill: #8FBC8F; 
  stroke: #8FBC8F; 
}

.chart-container .bar-chart .XAxis .recharts-cartesian-axis-tick {
  font-size: 14px;
  color: #333;
  transform: rotate(-45deg);
  text-anchor: end;
}

.chart-container .bar-chart .Tooltip {
  background-color: #f9f9f9;
  border-color: #ddd;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  color: #333;
}

.chart-container .bar-chart .Legend {
  padding: 10px;
}

.container {
  padding: 100px; 
  background: #f9f9f9; 
  margin-top: -5px;
}

.controls {
  display: flex;
  justify-content: space-between; 
  margin-bottom: 30px; 
  align-items: center;
}

.category-select {
  padding: 10px 15px; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 16px; 
  cursor: pointer; 
}

.stats-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  gap: 20px;
}

.stat-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px; 
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px; 
}

.stat-title {
  font-size: 30px;
  color: #333;
  margin-bottom: 20px;
}

.chart-container {
  width: 100%;
  min-height: 500px; 
}

.Tooltip {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  color: #333;
}


.Legend {
  padding: 10px;
}


.CartesianGrid {
  stroke: #eee; 
}


.Axis {
  font-size: 14px;
  color: #666;
}


.XAxis .recharts-cartesian-axis-tick {
  font-size: 14px;
  color: #333;
  transform: rotate(-45deg);
  text-anchor: end;
}

.pie-chart {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0; 
  width: 80%;
  max-width: 500px; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pie-chart-title {
  font-size: 24px;
  color: #000000;
  margin-bottom: 20px;
}

.pie-chart .ResponsiveContainer {
  width: '100%';
  height: 500px; 
}


.pie-chart .Legend,
.pie-chart .Tooltip {
  margin-top: 15px; 
}


.pie-chart .recharts-sector {
  stroke: #fff; 
  stroke-width: 2px;
}


.pie-chart .Tooltip {
  background-color: #f9f9f9; 
  border-color: #ddd; 
  border-radius: 8px; 
}

/* Stats.css */
.apply-filter-button {
  background-color: rgb(143, 185, 143) !important; 
  color: white !important; 
  border: none !important; 
  padding: 10px 20px !important; 
  border-radius: 5px !important; 
  cursor: pointer; 
  transition: background-color 0.3s; 
}

.apply-filter-button:hover {
  background-color: rgb(119, 153, 119) !important; 
}

@media (max-width: 375px) {
  .charts-container {
    flex-direction: column; 
  }
  .stat-card, .pie-chart {
    width: 100%; 
  }

  .pie-chart {
    width: 100%; 
    margin-top: 20px; 
  }

  .chart-container {
    min-height: 200px;
  }
  
  
  .stat-card {
    width: 100%;
    padding: 15px; 
  }
  
  .XAxis .recharts-cartesian-axis-tick {
    transform: rotate(-45deg); 
    text-anchor: end;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1000; 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color: #333;
    cursor: pointer;
  }
}



