/* AdminLogin.css */

.admin-login-container {
  height: 80vh; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  overflow: hidden; 
}

.admin-login-container h1 {
  color: rgb(143, 185, 143); 
  text-align: center;
  margin-bottom: 30px;
}

.password-input-wrapper {
  margin-bottom: 20px; 
}

.admin-login-container button {
  width: 100%; 
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px !important;
}

.forgot-password-text {
  cursor: pointer;
  margin-top: 20px !important;
  text-align: center;
  width: 100%; 
}

.error {
  color: #e74c3c;
  text-align: center;
}

@media only screen and (max-width: 375px) {
  .admin-login-container {
    justify-content: center; 
    align-items: center; 
    text-align: center; 
    padding-right: 20px; 
  }
}