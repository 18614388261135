/* Add.css */


.outer-container {
  display: flex;
  flex-direction: column;
  gap: 20px; 
  padding: 100px; 
  background-color: #ffffff; 
  text-align: left; 
}

.add-items-container {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 20px;
  text-align: left; 
}

.add-items-container .ui.form .field {
  text-align: left; 
}

.add-items-container .ui.form .field label {
  text-align: left; 
  display: block; 
  margin-bottom: 5px; 
}

.add-items-container .ui.form .field input,
.add-items-container .ui.form .field select {
  width: 100%; 
  text-align: left; 
}

.add-items-container .ui.segment {
  padding: 20px; 
  text-align: left; 
}
.Add-items-container .field
{
  text-align: left; 

}

.undo-buttons-container {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center; 
  gap: 20px; 
}

.removed-items-table {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px; 
}

.ui.button.green.large {
    background-color: rgb(143, 185, 143);
    color: white;
    font-size: 20px; 
    padding: 20px 70px; 
    
    margin-left: 20px !important;
    
}

.ui.basic.segment {
    margin-top: 20px; 
}

.success-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
  font-size: 16px;
  color: #333;
}

@media (max-width: 800px) {
  .outer-container {
    padding-top: 90px !important; 
    padding: 50px; 
  }

  .add-items-container, .undo-buttons-container, .removed-items-table {
    box-shadow: none; 
    padding: 10px; 
  }

  .add-form .field, .add-form .ui.button {
    width: 100%; 
    margin-bottom: 10px; 
  }

  .add-form .ui.button.large {
    padding: 12px 20px; 
    font-size: 14px; 
  }

  .success-popup {
    top: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
    width: 90%;
    font-size: 14px; 
  }
}

