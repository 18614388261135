/* Header.css */

.header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px;

  position: fixed;
  width: 100%;
  z-index: 2;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.burger-button {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(143, 185, 143);
  color: white;
  border: none;
  padding: 25px;
  cursor: pointer;
  z-index: 5;
  margin-bottom: 50px;
}

.header-logo {
  cursor: pointer;
  margin-left: 100px;
}


.custom-logo {
  width: 50px;
  max-width: 100%;
  height: auto;
}

.header-text {
  margin-left: 25px;
  color: rgb(143, 185, 143);
  font-size: 27px;
}

.mini-text {
  font: Montserrat, sans-serif;
  font-size: 14px;
  color: rgb(4, 4, 4);
  margin-top: -5px;
  align-self: flex-end;
}

.sidebar {
  height: 100%;
  width: 70px;
  background-color: #EBEBEB;
  overflow-x: hidden;
  padding-top: 90px;
  position: fixed;
  margin-top: 10px;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  transition: width 0.1s ease;
}

.sidebar.expanded {
  width: 250px;
  align-items: center;
  text-align: center;
}

.sidebar a i {
  font-size: 25px;
  margin-bottom: 1px;
}

.sidebar a+a {
  margin-top: 30px;
}

.sidebar a {
  padding: 10px 10px;
  text-decoration: none;
  font-size: 12px;
  color: #606060;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 100%;
  box-sizing: border-box;
  margin: 10px;
  align-items: center;
  text-align: center;
}

.sidebar a:hover {
  background-color: rgb(143, 185, 143);
  opacity: 90%;
  border-radius: 10px;
  padding: 10px;
  padding-top: 10px;
  color: white;
  transition: background-color 0.1s ease, color 0.2s;

}

.sidebar a:hover i,
.sidebar a:hover span {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.sidebar .sidebar.expanded:hover span {
  color: white;
}

.sidebar a span.small-text {
  font-family: Garamond, serif;
  font-size: 15px;
  margin-top: 40px;
  transition: opacity 0.2s ease;
}

.sidebar:not(.expanded) a span.small-text {
  display: block;
  opacity: 1;
}

.sidebar.expanded a {
  flex-direction: column;
  justify-content: center;
}

.sidebar.expanded a span {
  font-size: 20px;
  margin-left: 0;
  margin-top: 10px;
  display: block;
}

.sidebar.expanded a:after {
  content: attr(data-desc);
  font-size: 12px;
  margin-top: 10px;
  display: block;
}

.menu-btn .fa-times {
  display: none;
}

.menu-btn.close .fa-times {
  display: block;
}

.menu-btn.close .fa-bars {
  display: none;
}

.user-profile {
  position: fixed;
  right: 50px;
  top: 10px;
  display: flex;
  align-items: center;
  z-index: 3;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.user-profile .user-profile-pic {
  width: 80px;
  height: 70px;
  border-radius: 50%;
  font-size: 40px;
  margin-top: 15px;
  color: rgb(143, 185, 143);

}

.user-role {
  font-size: 20px;
  color: #606060;
  margin-bottom: 35px;
  margin-left: 0px;
  align-items: center;
}

.user-profile-popup {
  margin-top: -20px !important;
}

.user-profile .user-profile-popup .sign-out-popup-button {
  background-color: #db2828 !important;
  color: #fff !important;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
 
}

::-webkit-scrollbar-thumb {
  background-color: rgb(143, 185, 143);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
}

@media only screen and (max-width: 800px) {
  .header {
    padding-top: 30px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-text {
    display: none;
  }

  .mini-text {
    display: none;
  }

  .burger-button {
    top: 70px;
    left: 0px;
    padding: 10px;
    font-size: 14px;
  }

  .custom-logo {
    position: center;
    left: -60px;
    bottom: 5px;
    width: 50px;
    height: 50px;
  }

  .user-profile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 20px;
    top: 10px;
  }

  .user-info .weather-info {
    display: flex;
    align-items: center;
    position: absolute;
    right: 60px;
    top: 10px;
  }

  .sidebar {
    width: 40px;
    margin-top: 30px;
  }

  .sidebar.expanded {
    width: 100px;
  }

  .sidebar a i {
    font-size: 20px;
  }

  .sidebar a span {
    display: none;
    font-size: 10px;
  }

  .sidebar a:after {
    font-size: 10px;
  }

}