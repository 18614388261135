
.vol-settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 40px; 
}

.vol-settings-image {
  max-width: 80%; 
  height: auto; 
  margin-top: 60px; 
  margin-bottom: 20px; 
}

.sign-out-button {
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #db2828 !important; 
  color: white !important;
  transition: background-color 0.3s ease;
}

.sign-out-button:hover {
  background-color: #c0392b !important; 
}
