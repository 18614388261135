.Inventory {
  padding-top: 120px !important;
  padding: 100px; /* Adjust padding as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Update the look of the sort by drop-down menu */
.search-bar {
  margin-top: 50px; /* Adjust margin as needed */
  display: flex; /* Use flexbox for horizontal alignment */
  align-items: center; /* Align items vertically */
}

.search-bar input[type="text"] {
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px; /* Add some margin to separate it from the sort by dropdown */
}

.search-bar select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.Inventory .Grid.Column {
  
  display: flex;
  justify-content: center; /* Center the segments horizontally */
  align-items: center; /* Center the segments vertically */
  font-size: 20px;
}

.Inventory .Segment {
 
  width: 50%; /* Make segments fill the column width */
  /* Add additional styling as needed */
}


/* Inventory.css */
.ui.grid>.row>.column, .ui.grid>.column {
  padding: 8px; /* Reduced padding */
}

.ui.segment {
  padding: 10px; /* Reduced segment padding */
  text-align: center; /* Center-align the content for better readability */
}

/* Additional style adjustments for segment content */
.ui.segment h3 {
  margin: 10px 0 5px; /* Adjust the margin around the <h3> tags */
  font-size: 1em; /* Adjust the font size if necessary */
}

.ui.segment p {
  margin: 0; /* Remove the margin from <p> tags to save space */
  font-size: 0.9em; /* Adjust the font size if necessary */
}



@media only screen and (max-width: 800px) {
  .Inventory {
    padding-left: 60px !important; 
    padding-right: 20px !important; 
    margin: 0 auto;
    max-width: 100%; 
    overflow-x: hidden; 
  }

  .Inventory .Grid {
    display: block; 
  }

  .Inventory .Grid.Row {
    display: block; 
  }

  .Inventory .Grid.Column {
    width: 100% !important; 
    margin-bottom: 30px; 
  }

  .Inventory .Grid.Column .Segment {
    text-align: center; 
    padding: 20px; 
    margin-bottom: 30px; 
    margin-top: 30px; 
  }

  .Inventory .Grid.Column .Segment h3,
  .Inventory .Grid.Column .Segment p {
    font-size: 16px; 
  }

  
  .Inventory .search-bar {
    display: flex; 
    flex-wrap: wrap;
    justify-content: center; 
    margin-bottom: 20px; 
  }

  .Inventory .search-bar input[type="text"],
  .Inventory .search-bar select {
    width: 100%; 
    padding: 10px;
    margin-bottom: 10px; 
  }

  .Inventory Table {
    width: 100%; 
    margin-top: 20px; 
  }

  .Inventory Table thead th {
    padding: 12px; 
    font-size: 14px; 
  }

  .Inventory Table tbody td {
    padding: 12px; 
    font-size: 14px; 
  }
}
