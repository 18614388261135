/* Settings Container */

.settings-container {
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 40px;
  padding-left: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 800px;
  margin-left: 100px;
  margin-top: 100px;
  margin-bottom: 100px !important;

}

.admin-controls-container {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-buttons {
  display: flex;
  padding-top: 15px;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  width: 100%;
}


.settings-container table {
  width: 100%;
  border-collapse: collapse;
  margin-left: 0;
}

.settings-container th,
.settings-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.settings-container th {
  background-color: #f2f2f2;
}

.color-button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out;
}

.color-button:hover {
  filter: brightness(85%);
}


.session-id {
  display: inline-block;
  vertical-align: middle;
}


.button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out;
}

.sign-out-button {
  background-color: #db2828 !important;
  color: white !important;
  margin-top: 30px;
  padding: 15px 25px !important;
  font-size: 14px !important;
}

.sign-out-button:hover {
  background-color: #c0392b;
}

.modal {
  width: auto;
  margin: 0 auto;
}

.modal-content {
  padding-top: 40px !important;
  max-height: 650px;
  overflow-y: auto;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.modal-content-new-user {
  width: auto;
  margin: 0 auto;
}


.ui.modal>.content {
  padding-top: 70px;
}


.modal-content-custom {
  padding-top: 100px !important;
}

.modal-users-content {
  padding-top: 100px !important;
}

.add-user-button {
  margin-top: 20px !important;
}

.settings-container .delete-sessions {

  justify-content: space-between;
  margin-top: 20px;
  margin-left: 0px !important;
}

.custom-margin-sign-out {
  margin-bottom: 20px;
}

.users-buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}


@keyframes glow {
  0% {
    text-shadow: 0 0 8px rgba(143, 185, 143, 0.7), 0 0 10px rgba(163, 205, 163, 0.8);
  }

  50% {
    text-shadow: 0 0 20px rgba(163, 205, 163, 1), 0 0 30px rgba(183, 225, 183, 1), 0 0 40px rgba(203, 245, 203, 1);
  }

  100% {
    text-shadow: 0 0 8px rgba(143, 185, 143, 0.7), 0 0 10px rgba(163, 205, 163, 0.8);
  }
}

.session-active {
  color: rgb(143, 185, 143);
  animation: glow 2s infinite ease-in-out;
  
}

@media only screen and (max-width: 800px) {

  
  .ui.modal {
    top: 10%;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    max-height: 100vh;
    
  }

  .modal-content-custom,
  .modal-users-content {
    padding: 40px;
    padding-top: 30px;
    max-height: 80vh;
    overflow-y: auto;
    
  }

  .modal-content-custom,
  .modal-users-content .add-user-button {
    margin-top: 70px;
   
  }

  .modal-content-custom,
  .modal-users-content {
    margin-top: 70px;
  }

  .manage-donors {
    margin-top: 70px !important;
  }

  .modal-content-custom input[type="email"],
  .modal-content-custom select {
    width: 100%;
    margin-bottom: 10px;
  }
}