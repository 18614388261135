.RemovedItems {
  padding: 30px;
  margin: 80px;
}

.remove-inputs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 20px;
}

.input-field input[type="number"] {
  width: 150px; /* Adjusted width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
}

.ui.segment {
  padding: 20px;
  margin-bottom: 20px; 

}


.button {
  padding: 10px 15px;
  font-size: 14px;
  margin-left: 0 !important; 
}

.input-container {
  background-color: #f2f2f2; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; 
}

.input-field {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.input-field label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.input-field input[type="number"] {
  background-color: #ffffff; 
  border: 2px solid #cccccc; 
  transition: border-color 0.3s ease; 
}

.show-button {
  padding: 13px 20px !important; 
  font-size: 16px; 
  margin-left: 20px !important; 
}

.custom-remove-button {
  font-size: 12px !important;
  padding: 10px 10px !important;
}

.search-and-sort {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.remove-weight-button {
  padding: 12px 12px !important; 
  font-size: 14px; 
  margin-left: 20px !important; 
  align-self: center; 
}


.search-and-sort input[type="text"] {
  width: 350px; 
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
}

.search-and-sort select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.success-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
  font-size: 16px;
  color: #333;
}

@media only screen and (max-width: 800px) {
  .RemovedItems {
    padding-top: 90px !important; 
    padding: 70px; 
    margin: 0 auto; 
    max-width: 100%; 
  }
  
  .remove-inputs, .search-and-sort, .input-container {
    flex-direction: column; 
    align-items: center; 
  }

  .input-field, .input-field input[type="number"], .search-and-sort input[type="text"], .search-and-sort select {
    width: 100%; 
    box-sizing: border-box; 
  }

  .button, .remove-weight-button, .show-button {
    width: 100%; 
    margin-top: 10px;
  }

  .search-and-sort {
    width: 100%; 
    margin: 20px 0; 
    padding: 0 10px; 
  }

  .search-and-sort input[type="text"], .search-and-sort select {
    margin-bottom: 10px; 
  }

  .search-and-sort select, .show-button {
    margin-bottom: 15px; 
  }
}




