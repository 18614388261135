/* Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  justify-content: center; 
  text-align: center; 
  height: 80vh;
  background-color: #ffffff; 
  padding: 20px;
}

.login-header {
  text-align: center;
  margin-bottom: 20px; 
}

.login-header img {
  max-width: 300px; 
  margin-bottom: 10px; 
}

.login-header h1 {
  font-size: 30px;
  margin-bottom: 10px;
}

.login-header p {
  font-size: 14px;
  margin-bottom: 10px; 
}

.role-selection {
  display: flex;
  flex-direction: column;
  align-items: center; 
  
}

.role-selection button {
  margin: 10px 0;
  padding: 15px 30px;
  font-size: 24px;
  font-family: Arial, serif;
  cursor: pointer;
  background-color: rgb(143, 185, 143) !important;
  color: white !important; 
  border: 2px solid rgb(143, 185, 143);
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.role-selection button:first-child {
  margin-top: 20px;
  margin-bottom: 40px;
}

.role-selection button:hover {
  background-color: #3e8e41 !important; 
}

.tooltip {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}


.tooltip .icon {
  font-size: 20px; 
  color: rgb(143, 185, 143); 
}

.custom-tooltip .ui.popup {
  font-size: 12px; 
  background-color: #f0f0f0; 
  color: black; 
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

@media only screen and (max-width: 480px) {
  .login-container {
    padding: 20px; 
  }

  .login-header img {
    max-width: 220px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .login-header h1 {
    font-size: 19px;
  }

  .login-header p {
    font-size: 10px;
  }

  .role-selection {
    width: 100%; 
  }

  .role-selection button {
    width: auto; 
    padding: 10px 20px;
    font-size: 18px;
    margin: 0 10px; 
  }

  .tooltip {
    position: absolute;
    bottom: 20px; 
  }
}

@media only screen and (min-width: 1025px) {
  
  .role-selection button {
    max-width: 300px; 
    margin: 10px; 
  }

  .tooltip {
    position: absolute;
    bottom: 40px; 
  }
}
